import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'startseite',
    loadChildren: () => import('./pages/startseite/startseite.module').then( m => m.StartseitePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'standort',
    loadChildren: () => import('./pages/standort/standort.module').then( m => m.StandortPageModule)
  },
  {
    path: 'infobutton',
    loadChildren: () => import('./pages/infobutton/infobutton.module').then( m => m.InfobuttonPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path:'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path:'camera',
    loadChildren: () => import('./pages/camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path:'registerfotograf',
    loadChildren: () => import('./pages/registerfotograf/registerfotograf.module').then( m => m.RegisterfotografPageModule)
  },
  {
    path:'bearbeiten',
    loadChildren: () => import('./pages/bearbeiten/bearbeiten.module').then( m => m.BearbeitenPageModule)
  },
  {
    path:'bearbeitenfotograf',
    loadChildren: () => import('./pages/bearbeitenfotograf/bearbeitenfotograf.module').then( m => m.BearbeitenfotografPageModule)
  },
  {
    path:'profilefotograf',
    loadChildren: () => import('./pages/profilefotograf/profilefotograf.module').then( m => m.ProfilefotografPageModule)
  },
  {
    path:'buchen',
    loadChildren: () => import('./pages/buchen/buchen.module').then( m => m.BuchenPageModule)
  },
  {
    path:'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path:'detail/:id',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path:'chatgesendet',
    loadChildren: () => import('./pages/chatgesendet/chatgesendet.module').then( m => m.ChatgesendetPageModule)
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
