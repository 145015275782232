import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public storage: Storage,
    public renderer: Renderer2,
    public router: Router,
    private nav: NavController,
    private toastcontroller: ToastController,
  ) { }
  darkmode: any;
  id: any;
  beschreibung: any;
  ngOnInit() {

    this.darkmode=localStorage.getItem('colortheme');
    console.log(this.darkmode);


    if(this.darkmode==='true'){
      this.renderer.setAttribute(document.body, 'color-theme', 'dark');
    }
    else{
          this.renderer.setAttribute(document.body, 'color-theme', 'light');
    }   
    
    
  }

  chat(){
    this.id==='null';
    this.id=localStorage.getItem('id');
    console.log(this.id);

    if(this.id==='null' || this.id===null){
      this.presentToastLeer();
    }
    else{
        this.router.navigate(['/chat']);
    }
  }


  async presentToastLeer() {
    const toast = await this.toastcontroller.create({
      message: 'Du musst angemeldet sein, um in deinen Chat einsehen zu können.',
      duration: 2000
    });
    toast.present();
  }


  login(){
    this.id==='null';
    this.id=localStorage.getItem('id');
    this.beschreibung=localStorage.getItem('Beschreibung');
    console.log(this.id);

    if(this.id==='null' || this.id===null){
      this.router.navigate(['/login']);
    }
    else{
         if(this.beschreibung==='null')
          {
        this.router.navigate(['/profile']);
          }
          else{
          this.router.navigate(['/profilefotograf']);
              }
          
    }
  }
}