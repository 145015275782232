import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibWFudTA0IiwiYSI6ImNsZXY5aW5vNTFvcGkzc3AxeGlrbThtd2cifQ.C_ElcX4RlB1MS_U4zkcFRw';


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, IonicStorageModule.forRoot()],
  providers:
  [Geolocation, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  EmailComposer, NativeGeocoder  
],
  bootstrap: [AppComponent],
})
export class AppModule {}





